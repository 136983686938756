$background: $base03;
$foreground: $base0;

* {
  font-family: monospace !important;
  font-size: 32px !important;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.container {
  display: flex;
  flex-wrap: nowrap;

  flex-direction: column;

  background-color: $background;
  color: $foreground;
  height: 100%;
}

.chat-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  order: 0;
  flex-grow: 9999;
}

textarea.input-entry {
  width: 99%;
  border: 0;
  appearance: none;

  outline: none;

  background-color: $background;
  color: $foreground;
  height: 1em;
  resize: none;

  padding-left: 4px;
}

.input {
  order: 1;
  flex-grow: 1;
}

.channels {
  order: 1;
  flex-grow: 1;
}

.display {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 4px;


  padding-left: 4px;
  order: 0;
  background-color: $base02;
  flex-grow: 9999;
  overflow-x: hidden;
  overflow-y: auto;
}

.users {
  order: 2;
  flex-grow: 1;
  display: none;
}
